.dx-widget,
.dx-overlay-wrapper,
.dx-overlay-wrapper input {
  font-family: Noto Sans KR, DXIcons, sans-serif;
}

/* popover */
.dx-popup-wrapper > .dx-overlay-content {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #D9E0E5;
  overflow: hidden;
}

.dx-popup-content {
  padding: 0;
}

.dx-popup-content button,
.dx-popup-content a {
  display: block;
  text-align: left;
  width: 100%;
  color: #666666;
  padding: 10px 8px;
  -webkit-padding-before: 10px;
  -webkit-padding-end: 8px;
  -webkit-padding-after: 10px;
  -webkit-padding-start: 8px;
  display:-webkit-box;
  -webkit-box-orient: vertical;
}

.dx-popup-content button:hover,
.dx-popup-content a:hover {
  color: #444444;
  background-color: #F1F3F6;
}

.dx-popup-content button:disabled {
  color: #666666;
  background-color: transparent;
}

/* data-grid */
.dx-datagrid {
  border-bottom: 1px solid #DCE0E4;
}

.dx-datagrid-headers,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none;
  color: #637381;
  background-color: #F4F6F8;
}

.dx-datagrid .dx-datagrid-table .dx-header-row > td {
  padding-top: 0;
  padding-bottom: 0;
  height: 55px;
}

.dx-datagrid-rowsview .dx-row:hover {
  background-color: #F6F7F8;
}

.dx-datagrid .dx-row-lines > td {
  color: #444444;
  border-bottom: none;
  height: 65px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
  vertical-align: middle;
  border-left: none;
  border-right: none;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td:not(:last-child) {
  border-left: 2px solid #FFFFFF;
  border-right: 2px solid #FFFFFF;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row:first-child > td:first-child {
  border-left: none;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row:first-child > td:nth-last-child(2) {
  border-right: none;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row:first-child > td:nth-child(2) {
  border-bottom: 2px solid #FFFFFF;
}

.dx-row > tr > td {
  text-align: center;
  height: 65px;
}

.dx-datagrid-rowsview.dx-empty {
  height: auto;
}

.datagrid-checkbox .dx-row > tr > td {
  padding: 0;
}

/* date-range-box */
.dx-texteditor.dx-editor-filled {
  background-color: transparent;
  width: 285px;
  height: 38px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #DCE0E4;
}

.dx-texteditor.dx-editor-filled.small {
  width: 150px;
}

.dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-filled .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-filled .dx-texteditor-input {
  padding: 0 10px;
  text-align: center;
}

.dx-texteditor.dx-editor-filled::after {
  border-bottom: none;
}

.dx-daterangebox-with-floating-label.dx-editor-filled .dx-texteditor-label {
  display: none;
}

.dx-daterangebox .dx-datebox.dx-start-datebox.dx-editor-filled .dx-texteditor-input,
.dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input {
  font-size: 14px;
  padding: 0 10px;
}

.dx-daterangebox-separator {
  padding-left: 0;
}

/* chart */
#doughnut {
  width: 300px;
  height: 300px;
}